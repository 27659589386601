import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import axios from 'axios';
import NavBar from '../components/common/NavBar'
import CareerIntro from '../components/about/career/CareerIntro'
import CareerTestimonials from '../components/about/career/CareerTestimonials'
import CareerProcess from '../components/about/career/CareerProcess';
import OpenPositions from '../components/about/career/OpenPositions';
import CareerTechStack from '../components/about/career/CareerTechStack';
import Faq from '../components/common/Faq.js'

const Career = ({ data, location }) => {
  const faqs = data.allCareerFaqJson.edges.map(e => ({
    question: e.node.question,
    answer: e.node.answer,
    id: e.node.id
  }));

  const techStackList = data.allCareerTechStackJson.edges.map(e => ({
    name: e.node.name,
    stack: e.node.stack,
    codebase: e.node.codebase,
    image: e.node.image.childImageSharp.fluid.src,
    tools: e.node.tools,
    hardware: e.node.hardware,
    communication: e.node.communication
  }))

  const employeesNodes = data.employees.edges
  const [isFailed, setIsFailed] = useState(false);
  const [jobOffers, setJobOffers] = useState([]);

  useEffect(() => {
    async function getJobOffers() {
      try {
        let feed = await axios.get('https://zaven.elevato.net/services/JobOffersService/1/1/9999?format=json');
        setJobOffers(feed.data);
      } catch (error) {
        setIsFailed(true)
      }
    }
    getJobOffers()
  }, [])

  return (
    <>
      <Helmet
        title={
          'IT jobs in Wrocław: career as web developer in software house | Work in Zaven'
        }
        meta={[
          {
            name: 'description',
            content:
              'Work with specialists oriented towards international markets. Zaven software house gives you an opportunity to develop your skills in the growing IT sector.',
          },
          {
            name: 'keywords',
            content:
              'full stack developers, web development Poland, developers outsourcing, top mobile developers Poland',
          },
        ]}
      />
      <NavBar fixedPosition transparentBackground location={location} />
      <CareerIntro />
      <OpenPositions jobsNodes={jobOffers} isFailded={isFailed} />
      <CareerTechStack techStackList={techStackList} />
      <CareerTestimonials employeesNodes={employeesNodes} />
      <CareerProcess />
      <Faq listOfFaqs={faqs} />
    </>
  )
}

export default Career

export const CareerQuery = graphql`
  query CareerQuery {
    contactCareer: dataJson {
      ...Contact
    }
    employees: allEmployeesJson(filter: { message: { ne: null } }) {
      ...Employees
    }
    jobs: allJobsYaml(filter: { active: { eq: true } }) {
      ...Jobs
    }
    all_technologies: allTechnologiesJson {
      ...Technologies
    }
    allCareerBenefitsJson {
      ...Benefits
    }
    allCareerFaqJson {
      ...CareerFaqs
    }
    allCareerTechStackJson {
      ...CareerTechStack
    }
  }
`
