import React from 'react'
import ProcessGraph from './ProcessGraph';
import avatar from '../../../assets/images/career/career-process-contact.png';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Container, Col, Row} from 'reactstrap'

const CareerProcess = () => {
  return (
    <section className='career-process'>
    <Container>
    <h1 className='career-process-title'>Our recruitment process</h1>
    </Container>
      <ProcessGraph />
      <div className='container'>
      <div className="row pt-5 flex-column-reverse flex-md-row align-items-center">
        <div className="col-md-6 col-sm-12 career-process-avatar-container">
          <img src={avatar} className='career-process-avatar' alt='avatar' />
        </div>
        <div className="col-md-6 pl-5 career-process-knowMore">
          <h2 className='text-center text-md-left mb-5'>
            <strong>Want to know more?</strong> <br />
            Give me a call <br />
            or drop me a line
          </h2>
          <p className="lead text-bold mb-1"><strong>Marta Dałek</strong></p>
          <p className='lead text-center text-md-left mb-1'>
            <FontAwesomeIcon icon={faEnvelope} className="fa-fw text-muted mr-2" />
            <a
              href={`mailto:work@zaven.co`}
              className="link-unstyled"
            >
              work@zaven.co
            </a>
          </p>
          <p className='lead text-center text-md-left'>
            <FontAwesomeIcon icon={faPhone} className="fa-fw text-muted mr-2" />
            <a href='tel:48662422925' className="link-unstyled career-process-text">
              +48 662 422 925
            </a>
          </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CareerProcess;
