import React from 'react'
import FaqQuestion from './FaqQuestion'
import { Col, Container, ListGroup, Row } from 'reactstrap'

const Faq = ({ listOfFaqs }) => {
  const renderFaqs = faqs => {
    return faqs.map((faq, index) => (
      <FaqQuestion faq={faq} index={index} key={`faq-${index + 1}`} />
    ))
  }

  return (
    <section id="faq">
      <Container>
        <p className="seo-h1">F.A.Q.</p>
        <div className="faq-list">
          <Row>
            <Col xs="12">
              <ListGroup>{renderFaqs(listOfFaqs)}</ListGroup>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default Faq
