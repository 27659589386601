import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import careerProcess1 from '../../../assets/images/career/career-process-step-1.svg'
import careerProcess2 from '../../../assets/images/career/career-process-step-2.svg'
import careerProcess3 from '../../../assets/images/career/career-process-step-3.svg'
import careerProcess4 from '../../../assets/images/career/career-process-step-4.svg'
import careerProcess5 from '../../../assets/images/career/career-process-step-5.svg'
import careerProcessArrow from '../../../assets/images/career/career-process-arrow.svg'

const ProcessGraph = () => (
  <div id="process-graph">
    <Container>
      <Row className="text-center text-md-left">
        <Col sm="1"></Col>
        <Col sm="2" className="my-4">
          <Row className="process-graph-icons">
            <Col sm="6">
              <img
                src={careerProcess1}
                className="process-graph-icon"
                alt="career-process-1"
              />
            </Col>
            <Col sm="6">
              <img src={careerProcessArrow} alt="->" className="mt-1 d-none d-lg-block" />
            </Col>
          </Row>
          <div>
            <strong>
              Application <br className="d-none d-md-inline" />
              review
            </strong>
            <br />
            1&ndash;3 days
          </div>
          <img src={careerProcessArrow} alt="->" className="mt-4 d-inline-block d-sm-none process-graph-arrow-down" />
        </Col>
        <Col sm="2" className="my-4">
          <Row className="process-graph-icons">
            <Col sm="6">
              <img src={careerProcess2} alt="career-process-2" />
            </Col>
            <Col sm="6">
              <img src={careerProcessArrow} alt="->" className="mt-1 d-none d-lg-block" />
            </Col>
          </Row>
          <div>
            <strong>
              Intro <br className="d-none d-md-inline" />
              call
            </strong>
            <br />
            20 min.
          </div>
          <img src={careerProcessArrow} alt="->" className="mt-4 d-inline-block d-sm-none process-graph-arrow-down" />
        </Col>
        <Col sm="2" className="my-4">
          <Row className="process-graph-icons">
            <Col sm="6">
              <img src={careerProcess3} alt="career-process-3" />
            </Col>
            <Col sm="6">
              <img src={careerProcessArrow} alt="->" className="mt-1 d-none d-lg-block" />
            </Col>
          </Row>
          <div>
            <strong>
              Technical <br className="d-none d-md-inline" /> task
            </strong>
            <br />
            5&ndash;6 hours
          </div>
          <img src={careerProcessArrow} alt="->" className="mt-4 d-inline-block d-sm-none process-graph-arrow-down" />
        </Col>
        <Col sm="2" className="my-4">
          <Row className="process-graph-icons">
            <Col sm="6">
              <img src={careerProcess4} alt="career-process-4" />
            </Col>
            <Col sm="6">
              <img src={careerProcessArrow} alt="->" className="mt-1 d-none d-lg-block" />
            </Col>
          </Row>
          <div>
            <strong>
              Technical <br className="d-none d-md-inline" /> interview
            </strong>
            <br />1 hour
          </div>
          <img src={careerProcessArrow} alt="->" className="mt-4 d-inline-block d-sm-none process-graph-arrow-down" />
        </Col>
        <Col sm="2" className="my-4">
          <Row className="process-graph-icons">
            <Col sm="6">
              <img src={careerProcess5} alt="career-process-5" />
            </Col>
            <Col sm="6"></Col>
          </Row>
          <div>
            <strong>
              Feedback <br className="d-none d-md-inline" /> (always)
            </strong>
            <br />3 days
          </div>
        </Col>
        <Col sm="1"></Col>
      </Row>
    </Container>
  </div>
)

export default ProcessGraph
