import React from 'react'
import { Row, Container, Col} from 'reactstrap';
import careerTestimonials from '../../../assets/images/career/career-testimonials-img@2x.png';

const CareerTestimonials = ({ employeesNodes }) => {

  const renderTestimonials = nodes => {
    return nodes.map(({ node }, index) => (
      <Row key={index} className='d-flex align-items-end career-testimonials-item'>
        <Col sm="3" xl="2">
          <img
            src={node.avatar.childImageSharp.fluid.src}
            alt={node.name}
            className="rounded-circle career-testimonials-avatar"
          />
        </Col>
        <Col sm="9" xl="10">
        <p className="career-testimonials-message">{node.message}</p>
        <div className='d-flex'>
          <p className='career-testimonials-name'>
            <strong>{node.name}</strong>
            <br />
            {node.role}
          </p>
        </div>
        </Col>
      </Row>
    ))
  }

  return (
    <section className='career-testimonials d-flex justify-content-center align-items-center'>
      <Container>
        <Col sm="12" lg="6" className='career-testimonials-items'>
          {renderTestimonials(employeesNodes)}
        </Col>
        <img src={careerTestimonials} alt='career-testimonials' className='career-testimonials-image'/>
      </Container>
    </section>
  )
}

export default CareerTestimonials
