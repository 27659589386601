import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons'
import { Col, Collapse, ListGroupItem, Row } from 'reactstrap'

export default class FaqQuestion extends Component {
  constructor(props) {
    super(props)

    this.state = { isOpen: false }
  }

  toggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  render() {
    const { faq, index } = this.props
    return (
      <ListGroupItem
        onClick={this.toggle}
        id={`faq-toggler${index + 1}`}
        key={faq.id}
        className="faq-item"
      >
        <Row className="flex-nowrap">
          <Col
            xs="1"
            className="text-md-center text-right faq-counter-alignment"
          >
            {index + 1}
          </Col>
          <Col xs="10">
            <h4 className="list-group-item-heading">{faq.question}</h4>
            <Collapse isOpen={this.state.isOpen}>
              <div className="faq-answer">{faq.answer}</div>
            </Collapse>
          </Col>
          <Col xs="1">
            <div className="faq-icon">
              {this.state.isOpen ? (
                <FontAwesomeIcon size="lg" icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon size="lg" icon={faAngleDown} />
              )}
            </div>
          </Col>
        </Row>
      </ListGroupItem>
    )
  }
}
