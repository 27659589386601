import React from 'react'
import { Col, Row } from 'reactstrap'
import careerIntroMeetings from '../../../assets/images/career/career-intro-mettings.svg'
import careerIntroClients from '../../../assets/images/career/career-intro-clients.svg'
import careerIntroRemote from '../../../assets/images/career/career-intro-remote.svg'
import careerIntroAutomate from '../../../assets/images/career/career-intro-automate.svg'

const CareerIntro = () => (
  <section className='d-flex flex-column items-center justify-center career-intro bg-dark'>
    <div className="container">
      <h1 className="text-center display-3 text-white">Your place to <span class="text-italic">flow_</span></h1>
      <p className='lead text-center text-muted'>
        Tired of constant interruptions and rude clients? <br />
        This is how Zaven helps you focus and reduce stress:</p>
    </div>
    <div className='d-flex justify-content-center align-items-center'>
      <Row className='career-intro-items'>
        <Col sm="6" lg="3" className="career-intro-item d-flex flex-column align-items-center justify-content-between">
          <img src={careerIntroMeetings} alt='career-intro-meetings'/>
          <p className='career-text text-center career-intro-item-text pt-4'>
            Fewer meetings, <br />
            more Slack
          </p>
        </Col>
        <Col sm="6" lg="3" className="career-intro-item d-flex flex-column align-items-center justify-content-between">
          <img src={careerIntroClients} alt='career-intro-clients' className='career-intro-item-clientIcon'/>
          <p className='career-text text-center career-intro-item-text pt-4'>
            Cool clients, <br />
            who know their game
          </p>
        </Col>
        <Col sm="6" lg="3" className="career-intro-item d-flex flex-column align-items-center justify-content-between">
          <img src={careerIntroRemote} alt='career-intro-remote'/>
          <p className='career-text text-center career-intro-item-text pt-4'>
            Remote-friendly <br/>
            work culture
          </p>
        </Col>
        <Col sm="6" lg="3" className="career-intro-item d-flex flex-column align-items-center justify-content-between">
          <img src={careerIntroAutomate} alt='career-intro-automate'/>
          <p className='career-text text-center career-intro-item-text pt-4'>
            Less repetition, <br />
            more automation</p>
        </Col>
      </Row>
    </div>
  </section>
)

export default CareerIntro
