import React, { useState } from 'react'
import {
  Row,
  Col,
  Container,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const CareerTechStack = ({ techStackList }) => {
  const [selectedTech, setSelectedTech] = useState(techStackList[0])
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const handleChange = e => {
    setSelectedTech(
      techStackList.find(techStack => techStack.name === e.target.innerHTML)
    )
  }

  return (
    <section className="tech-stack">
      <Container>
      <Row className="mb-5">
      <Col lg="7">
        <h2 className="text-center text-lg-right text-white">
          Tech stack & environment
        </h2>
      </Col>
      <Col lg="5">
        <div className="d-flex align-items-baseline justify-content-center justify-content-sm-start">
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle
              caret
              color="primary"
              outline
              className="text-white"
            >
              {selectedTech.name}
            </DropdownToggle>
            <DropdownMenu className="tech-stack-header-dropdown-menu">
              {techStackList.map(techStack => (
                <DropdownItem
                  key={techStack.name}
                  item
                  onClick={handleChange}
                >
                  {techStack.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          <p className="d-none d-sm-inline-block tech-stack-pick text-muted ml-2">&larr; pick an example!</p>
        </div>
      </Col>
    </Row>
    <Row>
      <Col sm="12" lg="6">
        <div className="tech-stack-image text-center text-lg-right">
          <img src={selectedTech.image} alt="selected-tech" />
        </div>
      </Col>
      <Col sm="12" lg="1"></Col>
      <Col sm="12" lg="5">
        <div className="tech-stack-description">
          <div className="tech-stack-description-list">
            <div className="mt-3">
              <h5 className="text-muted text-uppercase my-0">Stack</h5>
              <p>{selectedTech.stack}</p>
            </div>
            <div className="mt-3">
              <h5 className="text-muted text-uppercase my-0">Codebase + delivery</h5>
              <p>{selectedTech.codebase}</p>
            </div>
            <div className="mt-3">
              <h5 className="text-muted text-uppercase my-0">Tools</h5>
              <p>{selectedTech.tools}</p>
            </div>
            <div className="mt-3">
              <h5 className="text-muted text-uppercase my-0">Hardware</h5>
              <p>{selectedTech.hardware}</p>
            </div>
            <div className="mt-3">
              <h5 className="text-muted text-uppercase my-0">Communication</h5>
              <p>{selectedTech.communication}</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
      </Container>
    </section>
  )
}

export default CareerTechStack
