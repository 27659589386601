import React from 'react'
import { faUserTimes, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import careerOfferBackground from '../../../assets/images/career/career-offers-bg.png'
import { Col, Row, Container, ListGroup, ListGroupItem } from 'reactstrap'

const replaceLastOccurrenceInString = (input, find, replaceWith) => {
  const lastIndex = input.lastIndexOf(find)
  if (lastIndex < 0) {
    return input
  }

  return (
    input.substr(0, lastIndex) +
    replaceWith +
    input.substr(lastIndex + find.length)
  )
}

const OpenPositions = ({ jobsNodes, isFailed }) => {
  const { length, [length - 1]: applyForJobItem } = jobsNodes

  const jobOffers = jobsNodes.filter(function(jobNode, index) {
    return index !== jobsNodes.length - 1
  })

  const renderJobs = nodes => {
    return nodes.map((node, index) => (
      <ListGroupItem
        key={index}
        className="open-positions-item d-flex align-items-center justify-content-between"
      >
        <div className="d-flex align-items-md-center">
          <FontAwesomeIcon icon={faUser} fixedWidth className="text-muted mr-2 my-1" />
          <div className="d-md-flex align-items-baseline">
            <h4 className="d-lg-inline-block my-0 mr-2">{node.Name}</h4>
            <p
              dangerouslySetInnerHTML={{ __html: node.shortDescription }}
              className="my-0 d-lg-inline-block text-sm text-muted"
            />
          </div>
        </div>
        <a
          className="btn btn-sm btn-primary"
          target="_blank"
          href={replaceLastOccurrenceInString(node.seoUrl, 'ja', 'j')}
        >
          Show offer
        </a>
      </ListGroupItem>
    ))
  }

  return (
    <section className="open-positions">
      <Container>
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <h1>Open positions</h1>
            <div className="pt-5">
              {isFailed ? (
                <p>
                  Error! Can't load open positions - contact us:
                  <a href="mailto:work@zaven.co" className="pl-1 link-unstyled">
                    work@zaven.co
                  </a>
                </p>
              ) : jobsNodes.length > 0 ? (
                <div>
                  <ListGroup>{renderJobs(jobOffers)}</ListGroup>
                  <ListGroup className="mt-1">
                    <ListGroupItem className="bg-transparent d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-md-center">
                        <FontAwesomeIcon icon={faUserTimes} fixedWidth className="text-muted mr-2 my-1" />
                        <div className="d-md-flex align-items-baseline">
                          <h4 className="my-0 mr-2 text-white d-block d-lg-inline-block">
                            ...no match?
                          </h4>
                          <p
                          className="my-0 d-block d-lg-inline-block text-sm text-muted"
                            dangerouslySetInnerHTML={{
                              __html: applyForJobItem.shortDescription,
                            }}
                          />
                        </div>
                      </div>
                      <a
                        className="btn btn-sm btn-link"
                        target="_blank"
                        href={replaceLastOccurrenceInString(
                          applyForJobItem.seoUrl,
                          'ja',
                          'j'
                        )}
                      >
                        Apply now
                      </a>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              ) : (
                <div className="col-sm-6 card-job-col">
                  <p>Loading...</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <img
                    src={careerOfferBackground}
                    style={{ width: '100%' }}
                    alt="jobs-background"
                  />
    </section>
  )
}

export default OpenPositions
